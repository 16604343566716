import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import UnderlinedHeading from '../components/underlined-heading';

const JourneyPage = () => {
  
  const navigateToSection = section => document.getElementById(section).scrollIntoView();

  let coords = [
    "192,157,518,526",
    "989,968,384,547",
    "998,442,1333,980",
    "1343,1155,1860,545",
    "1890,730,2496,1106",
    "2175,136,2607,626"
  ];

  const sections = [
    'launch',
    'early-operation',
    'cruise',
    'mars-orbit-insertion',
    'transition-to-science',
    'science'
  ]

  const resizeCoords = coords => {
    const img = document.getElementById('main-image');
    if (img) {
      const currentWidth = img.getBoundingClientRect().width;
      const ratio = currentWidth / 2616;
      coords = coords.split(",").map(e => Math.floor(Number(e.trim()) * ratio));
      const leftLine = coords[0];
      coords[0] = currentWidth - coords[2];
      coords[2] = currentWidth - leftLine;
      return coords.join(",");
    }
    return "0,0,0,0";
  }

  const [areas, setAreas] = useState([]);

  useEffect(() => {
    document.getElementById('main-image').onload = () => {
      coords = coords.map(coord => resizeCoords(coord));
      setAreas(coords.map((coord, index) => <area target="_blank" alt="" title="" href="" shape="rect" coords={coords[index]} onClick={(e) => { e.preventDefault(); navigateToSection(sections[index])}} />));
    }
  }, []);

  return (
    <Layout lang="ar">
      <SEO title="Home" />
      <main>
        <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
            <canvas width="100%" height="100%"></canvas>
            <img src={require('../assets/images/journey-background.jpg')} alt="" data-uk-cover />
            <div className="uk-overlay uk-position-cover header-overlay"></div>
        </div>
        <section style={{marginTop: '-250px', position: 'relative'}}>
          <div className="standard-container journey-page-container" dir="rtl">
            <div>
              <img src={require('../assets/images/ar-journey-1.jpg')} id="main-image" useMap="#image-map" />
              <map name="image-map">{areas}</map>
            </div>
            
            <div className="lighter-background" id="launch">
              <UnderlinedHeading text="الإطلاق" />
              <p>
              انطلق مشروع الإمارات لاستكشاف المريخ "مسبار الأمل" في رحلته إلى المريخ من مركز تانيغاشيما للفضاء باليابان. في تمام الساعة 1:58 صباحاً بتوقيت الإمارات من يوم 20 يوليو 2020. وكان فريق المهمة قد اختار إطاراً زمنياً لإطلاق المسبار خلاله ُيعرف بـ "نافذة الإطلاق" مدتها لحوال 30 يوماً وتمتد بين 14 يوليو 2020 و12 أغسطس 2020. وانطلق مسبار الأمل بنجاح على متن صاروخ
ميتسوبيشي (إتش 2 إيه) نحو الشرق في مسار يمتد فوق المحيط الهادئ.
              </p>
              <p>
              وخلال المرحلة الأولى من عملية الإطلاق، انطلق الصاروخ متسارعاً بعيداً عن الأرض، وتم خلال هذه المرحلة استخدام محركات الصاروخ التي تعمل بالوقود الصلب، وبمجرد اختراق الصاروخ للغلاف الجوي تم التخلص الغطاء العلوي للصروخ و الذي كان يحمي "مسبار الأمل" أثناء اختراق الصاروخ للغلاف الجوي للأرض.
              </p>
              <p>
              وفي المرحلة الثانية من عملية الإطلاق تم التخلص من محركات المرحلة الأولى ووضع المسبار في مدار الأرض . بعد ذلك تعمل محركات المرحلة الثانية على وضع المسبار في مساره نحو الكوكب الأحمر من خلال عملية محاذاة دقيقة مع المريخ. تكون سرعة المسبار في هذه المرحلة 11 كيلومتر في الثانية.
              </p>
              <img src={require('../assets/images/ar-journey-2.jpg')} />
            </div>

            <div className="lighter-background" id="early-operation">
              <UnderlinedHeading text="العمليات المبكر" />
              <p>
              بمجرد الإنفصال عن المرحلة الثانية من الصاروخ، تبدأت سلسلة من الأوامر المعدة مسبقاً بتشغيل مسبار الأمل، تشمل هذه العمليات تنشيط الكمبيوتر المركزي، وتشغيل نظام التحكم الحراري لمنع تجمد الوقود، وفتح الألواح الشمسية واستخدام مستشعرات المخصصة لتحديد موقع الشمس، لتبدأ بعدها مناورة تعديل موضع المسبار وتوجيه الألواح الشمسية نحو الشمس، من أجل بدء عملية شحن البطاريات الموجودة
على متن المسبار.
              </p>
              <p>
              وبمجرد الإنتهاء من العمليات السابقة يبدأ المسبار بإرسال سلسلة من البيانات تكون أول إشارة إلى تصل إلى كوكب الأرض، هذه الإشارة تم التقاطها من ِقبل شبكة مراقبة الفضاء العميق وبالأخص المحطة التي تقع في العاصمة الإسبانية مدريد. و بمجرد أن تلقا فريق المحطة الأرضية في دبي هذه الإشاره، بدأ فريق مشروع الإمارات لاستكشاف المريخ في إجراء سلسلة من الفحوصات للتأكد من سلامة المسبار والتي تستمر لمدة 45 يوم يعمل فيها فريق العمليات والفريق االهندسي للمسبار فحص جميع الأجهزة والتأكد من عمل الأنضمة على
الشكل الأمثل.
              </p>
              <p>
              وخلال رحلة مسبار الأمل نحو المريخ يعمل الفريق طوال الوقت على تجوية مسار الرحلة إلى المريخ من خلال ما ُيعرف بمناورات توجية
المسار. وقد نجح الفريق في إجراء أول مناورتين بنجاح خلال هذه المرحلة الأول في 11 أغسطس والثانية في 28 من أغسطس.
              </p>
              <p>
              وخلال الرحلة إلى المريخ سيقطع مسبار الأمل مسافة تمتد إلى 493,500,000 كيلومتر.
              </p>
              <img src={require('../assets/images/ar-journey-3.jpg')} />
            </div>

            <div className="lighter-background" id="cruise">
              <UnderlinedHeading text="الملاحة في الفضاء" />
              <p>
              خلال هذه المرحلة يدخل مسبار الأمل مرحلة العمليات الإعتيادية حيث يعمل الفريق على الإتصال مع مسبار 2-3 في الأسبوع لإجراء العمليات المطلوبة. ووفقاً للمتطلبات التي يراها الفريق قد يتم إجراء المزيد من مناورات توجيه المسار .
              </p>
              <p>
              وأيضاً خلال هذه المرحلة التي تستغرق 7 أشهر، يتم تشغيل الأجهزة العلمية لأول مره في الفضاء وفحصها وضبطها، ويتم فحص الأجهزة بتوجيهها نحو النجوم للتأكد من سلامة زوايا المحاذاة الخاصة بها والتأكد من أنها جاهزة للعمل بمجرد وصولها إلى المريخ.
              </p>
              <p>
              في نهاية هذه المرحلة يقترب "مسبار الأمل" من المريخ لتبدأ بها أهم مراحل المهمه وأكثرها خطورة. 
              </p>
              <img src={require('../assets/images/ar-journey-4.jpg')} />
            </div>

            <div className="lighter-background" id="mars-orbit-insertion">
              <UnderlinedHeading text="الدخول إلى مدار المريخ" />
              <p>
              خلال مرحلة الدخول إلى مدار المريخ، سيركز الفريق على إدخال مسبار الأمل في مدار التقاط حول المريخ بشكل آمن. ومن أجل إتمام هذه المهمة بنجاح سيتم حرق نصف كمية الوقود الموجودة في خزانات "مسبار الأمل" لإبطائه إلى الحد الذي يسمح
              </p>
              <p>
              بإدخاله في مدار الالتقاط. وستستمر عملي حرق الوقود باستخدام محركات الدفع العكسي (دلتا في) لمدة 30 دقيقة لتقليل سرعة المسبار من 121,000 كم/ساعة إلى 18,000 كم/ساعة. ونظراً لكونها عملية دقيقة، فقد تم تصميم المسبار لهذه المرحلة بحيث يتم تشغيله بشكل مستقل.
              </p>
              <p>
              ونظراً لأن هذه المرحلة لها نفس أهمية والخطورة كمرحلة الإطلاق فإنه سيتم إعادة فحص واختبار جميع الأجهزة الفرعية الموجودة على متنه قبل الانتقال إلى المرحلة العلمية كما تم في مرحلة العمليات المبكرة.
              </p>
              <img src={require('../assets/images/ar-journey-5.jpg')} />
            </div>

            <div className="lighter-background" id="transition-to-science">
              <UnderlinedHeading text="الانتقال إلى المرحلة العلمية" />
              <p>
              بمجرد اكتمال عمليات الدخول إلى مدار المريخ، سيكون أول اتصال مع للمسبار مع المحطة الأرضية عبر شبكة الاتصالات في إسبانيا.
              </p>
              <p>
              وتتمثل المرحلة التالية في رحلة مسبار الأمل، في الانتقال من مدار الالتقاط إلى مدار علمي مناسب حتى يتمكن من أداء مهامه العلمية المخطط لها. ويكون المدار العلمي بيضاوي الشكل وتصل مدة الدورة الواحدة حول الكوكب فيه إلى 40 ساعة، وفيه سيكون "مسبار الأمل" على ارتفاع 1000 كيلومتر فوق سطح المريخ وعلى بعد 49,380 كيلومتر منه.
              </p>
              <p>
              سيقوم مسبار الأمل، بالتقاط الصورة الأولى للمريخ عبر أجهزته العلمية، خلال وجوده في مدار الالتقاط. ويتم بعد ذلك جدولة الاتصال اليومي مع المحطة الأرضية حتى يتمكن فريق المشروع من إجراء عمليات تحميل سلسلة الأوامر وبيانات العمليات المختلفة.
              </p>
              {/* <p>
                After a period of testing and validation, spanning nearly 6 weeks from the Mars Orbit Insertion phase, the Hope Probe will move into the Science Orbit.
              </p> */}
              <img src={require('../assets/images/ar-journey-6.jpg')} />
            </div>

            <div className="lighter-background" id="science">
              <UnderlinedHeading text="المرحلة العلمية" />
              <p>
              خلال هذه المرحلة سيتخذ "مسبار الأمل" مداراً بيضاوياً حول المريخ على ارتفاع يتراوح بين 20,000 إلى 43,000 كيلومتر، يستغرق فيه المسبار 55 ساعة لإتمام دورة كاملة حول المريخ. و ُيعد المدار الذي اختاره فريق الإمارات لاستكشاف المريخ
ًً مبتكرا للغاية وفريدا من نوعه وسيسمح لمسبار الأمل بإمداد المجتمع العلمي بأول صورة متكاملة عن الغلاف الجوي لكوكب
المريخ وطقسه على مدار 24 ساعة في اليوم وطوال أيام الأسبوع.
              </p>
              <p>
              ستقتصر عدد مرات اتصال "مسبار الأمل" مع المحطة الأرضية على مرتين فقط في الأسبوع وتتراوح مدة الاتصال الواحد بين 6 إلى 8 ساعات، وتمتد هذه المرحلة لعامين من المخطط أن يلتقط المسبار خلالهما مجموعة كبيرة من البيانات العلمية عن الغلاف الجوي للمريخ وديناميكياته. وسيتم توفير هذه البيانات العلمية إلى المجتمع العلمي عبر مركز البيانات العلمية التابع
لمشروع الإمارات لاستكشاف المريخ.
              </p>
              <img src={require('../assets/images/ar-journey-7.jpg')} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default JourneyPage
